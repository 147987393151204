export const HomepageBox = [
  {
    title: '',
    description: '',
    secondDescription: '',
    className: 'box half box-dark',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-1.jpg'),
    buttonText: '',
    buttonClassname: 'btn-dark',
    linkClassname: 'btn-dark',
    linkName: '',
    linkTarget: '',
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 1,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    className: 'box half box-dark',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-2.jpg'),
    buttonText: '',
    linkClassname: 'btn-dark',
    linkName: '',
    linkTarget: '',
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 2,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    buttonText: 'Alle Leistungen',
    buttonLink: '/services',
    buttonClassname: 'btn-outline',
    className: 'box box-dark one-third',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-3.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 3,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    buttonText: 'Alle Leistungen',
    buttonLink: '/services',
    buttonClassname: 'btn-outline',
    className: 'box box-dark one-third',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-4.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 11,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    buttonText: 'Alle Leistungen',
    buttonLink: '/services',
    buttonClassname: 'btn-outline',
    className: 'box box-dark one-third',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-5.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 12,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    className: 'box box-dark half',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    linkClassname: 'btn-dark',
    linkName: 'Sprechen Sie uns an!',
    linkTarget: '/contact',
    imgpath: require('../images/box-home-6.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 13,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    className: 'box box-dark half',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    linkClassname: 'btn-dark',
    linkName: 'Sprechen Sie uns an!',
    linkTarget: '/contact',
    imgpath: require('../images/box-home-7.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 14,
    section: 1
  },

]

export const HomepageHero = [
  { 
    title: 'Shear. Shave. Shine.', 
    description: 'Herzlich willkommen bei XS-Barbershop – deiner Anlaufstelle für exzellente Haarschnitte und Bartfrisuren im <strong style="color: var(--primary-color);">Rhein-Main Gebiet</strong>! Seit unserer Gründung im Jahr 2017 haben wir uns einen festen Platz in der <strong style="color: var(--primary-color);">Top-Liga der Barbershops</strong> erobert und setzen dabei auf höchste <strong style="color: var(--primary-color);">Qualität</strong>, Leidenschaft und einen einzigartigen Service in einem einzigartigen <strong style="color: var(--primary-color);">Ambiente</strong>.', 
    className: 'hero', 
    containerClassName: 'hero-container', 
    buttonClass: 'btn-outline', 
    buttonText: 'Termin buchen', 
    buttonLink: '/termin', 
    imgClassName: '', 
    backgroundImage: require('../images/hero-bg.jpg'),
    cssid: 'hero-home', 
    id: 1 },
]

export const HomepageCta = [
  {
    headline: 'Weitere Leistungen',
    description: 'Ob Haarschnitt oder Bartpflege – wir bieten Qualität zu fairen Konditionen. Informiere dich über unsere Leistungen und finde heraus, wie wir deinen Stil perfektionieren können. Plane deinen nächsten Besuch im XS-Barbershop mit einem Blick auf unsere Angebote.',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Leistungen und Preise',
    buttonLink: '/services',
    imgClassName: '',
    id: 1,
    section: 1,
  },
  {
    headline: 'Du hast noch keinen Termin?',
    description: 'Wir wissen, dass dein Zeitplan genauso einzigartig ist wie du. Deshalb haben wir es so einfach wie möglich gemacht, einen Termin bei uns zu buchen. Nutze einfach unser bequemes Online-Buchungssystem, um den perfekten Zeitpunkt für deinen Besuch auszuwählen. Klicke auf "Termin buchen", wähle deinen bevorzugten Barber und sichere dir deinen Platz in unserem Stuhl.',
    className: 'cta full',
    containerClassName: 'cta-container  default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Termin buchen',
    buttonLink: '/termin',
    imgClassName: '',
    id: 2,
    section: 2,
  },

]

export const HomepageHighlights = [
  {
    title: 'VIP-Behandlung',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    category: 'VIP',
    description: 'Inkl. Waschen, Schneiden, Föhnen, Bartpflege Premium, Augenkorrektur und Gesichtsbehandlung',
    price: '€ 120',
    id: 1
  },
  {
    title: 'Trockenhaarschnitt',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    description: 'Inkl. Schneiden, Föhnen, Stylen',
    category: 'Haare',
    highlight: 'Jetzt buchen!',
    price: '€ 36',
    serviceId: "39531",
    id: 2
  },
  {
    title: 'Waschen - Schneiden - Föhnen',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    description: 'Inkl. Waschen, Schneiden, Föhnen, Stylen',
    category: 'Haare',
    highlight: 'Jetzt buchen!',
    price: '€ 39',
    serviceId: "39530",
    id: 3
  },
  {
    title: 'Bartpflege Premium',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    description: 'Bart trimmen, klassische Nassrasur, Behandlung mit einem warmen/kalten Handtuch (mit ätherischen Ölen) anschließend Rasur mit dem Messer, Rasierseife und Vorbehandlung',
    category: 'Bart',
    price: '€ 39',
    highlight: 'Jetzt buchen!',
    serviceId: "68787",
    id: 6
  },

]

export const HomepageHeadline = [
  {
    className: 'headline headline-center',
    containerClassName: 'headline-container',
    headline: 'Übersicht unserer Leistungen',
    id: 1,
    section: 2
  },
  {
    className: 'headline headline-center',
    containerClassName: 'headline-container',
    headline: 'Innenansicht XS-Barbershop',
    id: 2,
    section: 3
  },
  {
    className: 'headline headline-center',
    containerClassName: 'headline-container',
    headline: 'Impressionen XS-Barbershop',
    id: 3,
    section: 4
  },
]

export const HomepageModal = [
  {
    className: 'modal modal-center',
    containerClassName: 'modal-container',
    title: 'Willkommen beim XS-Barbershop',
    text: 'Wir haben das Theme der Website basierend auf deinen Einstellungen automatisch festgelegt. Dieses kannst du über den Button unten wechseln. Außerdem stehen dir weitere Anpassungsmöglichkeiten zur Verfügung.',
    button: 'Namen eingeben',
    ctaText: 'Weitere Anpassungen',
    ctaIcon: 'fas fa-palette',
    ctaLink: '/customization',
    id: 1,
    section: 1
  },

]


export const HomepageSlides = [
  {
    url: require('../images/slide-5.jpg'),
    alt: 'logo',
    id: 1
  },
  {
    url: require('../images/slide-2.jpg'),
    alt: 'logo',
    id: 2
  },
  {
    url: require('../images/slide-3.jpg'),
    alt: 'logo',
    id: 3
  },
  {
    url: require('../images/slide-4.jpg'),
    alt: 'logo',
    id: 4
  }
]

export const HomepageSections = [
  {
    title: "Ambiente",
    backgroundImage: require('../images/slide-1.jpg'),
    description: "Erlebe im XS-Barbershop die Symbiose aus Stil und Ambiente. Unsere Inneneinrichtung verknüpft Vintage-Chic mit modernem Flair, begleitet von einer beeindruckenden bemalten Decke, die die Sinne anspricht. Kostenlose erfrischende Kalt- und Heißgetränke vervollständigen das Wohlfühlerlebnis, während unsere erfahrenen Barbiere für deine Bedürfnisse sorgen. Tauche ein in eine Welt des Stils und der Entspannung – der XS-Barbershop heißt dich herzlich willkommen, das einzigartige Ambiente zu genießen!",
    id: 0,
    
  },
  {
    title: "Team",
    backgroundImage: require('../images/slide-2.jpg'),
    description: 'Im XS-Barbershop sind unsere Barbiere echte Profis mit einer Leidenschaft für ihr Handwerk. Ihr Ziel ist es, jedem Kunden den optimalen Haarschnitt zu bieten. Mit Sorgfalt und individueller Aufmerksamkeit sorgen sie dafür, dass jeder Schnitt genau auf die Bedürfnisse abgestimmt ist. Bei uns steht handwerkliches Können im Vordergrund, und jeder Besuch wird zu einer maßgeschneiderten Erfahrung.',
    id: 1
  },
  {
    title: "Über uns",
    backgroundImage: require('../images/slide-3.jpg'),
    description: "Von den Anfängen bis heute: Unser Barbershop im Rhein-Main-Gebiet hat sich in wenigen Jahren zu einer festen Größe entwickelt. Mit engagierten Barbiern, einem unverwechselbaren Ambiente und ständigem Fokus auf Kundenzufriedenheit sind wir zu einem bekannten Namen in der Region geworden. Unsere Entwicklung spiegelt unser Streben nach Qualität und Authentizität wider.",
    id: 2
  },
]

export const HomepageMeta = ''

