import {
  React} from "react";


function NavigationButtons(props) {
  const navigationbuttons = props.navigationbuttons;


  return (
    <>
      {navigationbuttons.map((navigationbuttons) => (
       <a key={navigationbuttons.id} href={navigationbuttons.link} className={navigationbuttons.className}>
       {navigationbuttons.icon && <span><i className={navigationbuttons.icon}></i></span>}{navigationbuttons.title && <span className="bold">{navigationbuttons.title}</span>}</a>
       ))}
    </>
  );
}

export default NavigationButtons







