export const NavItems = [
  {
    link: '/',
    title: 'Home',
    icon: 'fas fa-home',
    id: 1,
    transitionDelay: 600,
  },
  {
    link: '/termin',
    title: 'Termin',
    icon: 'fas fa-calendar',
    id: 2,
    transitionDelay: 550,
  },
  {
    link: '/about',
    title: 'Über Uns',
    icon: 'fas fa-info',
    id: 3,
    transitionDelay: 500,
  },
  {
    link: '/services',
    title: 'Leistungen',
    icon: 'fas fa-sitemap',
    id: 4,
    transitionDelay: 450,
  },
  {
    link: '/projects',
    title: 'Galerie',
    icon: 'fas fa-image',
    id: 5,
    transitionDelay: 400,
  },
  {
    link: '/contact',
    title: 'Kontakt',
    icon: 'fas fa-question',
    id: 6,
    transitionDelay: 350,
  },
  {
    link: '/customization',
    title: 'Anpassung',
    icon: 'fas fa-palette',
    id: 7,
    transitionDelay: 300,
    
  },
  {
    link: '/legal',
    title: 'Rechtliches',
    icon: 'fas fa-file-contract',
    id: 8,
    transitionDelay: 250,
  },
]

export const NavButtons = [
  {
    link: 'tel:+061034877822',
    title: 'Telefon',
    id: 1,
    icon: 'fas fa-phone',
    className: 'button-phone',
  },
  {
    link: 'mailto:info@xs-barbershop.com',
    title: 'E-Mail',
    icon: 'fas fa-envelope',
    id: 2,
    className: '',
  },
]