export const DefaultUserform = [
  {
    firstname: 'true',
    lastname: 'true',
    darkmodeswitch: 'true',
    savepersonaldata: 'true',
    colorchoice: 'true',
    id: 1,
    section: 1,
  }
]

export const parallaxData = [
  {
    headline: 'Dein Barbier in Langen',
    headlineOverlay: 'XS-Barbershop',
    productImageUrl: require('../images/razor-highlight.png'),
    description: 'Herzlich Willkommen bei XS-Barbershop – deiner Anlaufstelle für exzellente Haarschnitte und Bartfrisuren im Rhein-Main Gebiet! Seit unserer Gründung im Jahr 2017 haben wir uns einen festen Platz in der Top-Liga der Barbershops erobert und setzen dabei auf höchste Qualität, Leidenschaft und einen einzigartigen Service in einem einzigartigen Ambiente.',
    descriptionOverlay: 'Individuell. Präzise. Erfahren.',
    url: require('../images/hero-bg.jpg'),
    id: 1
  },
]

export const endlessSliderData = [
  {
    url: require('../images/box-home-1.jpg'),
    id: 1,
    section: 1
  },
  {
    url: require('../images/box-home-2.jpg'),
    id: 2,
    section: 1
  },
  {
    url: require('../images/box-home-3.jpg'),
    id: 3,
    section: 1
  },
  {
    url: require('../images/box-home-4.jpg'),
    id: 4,
    section: 1
  },
  {
    url: require('../images/box-home-5.jpg'),
    id: 5,
    section: 1
  },
  {
    url: require('../images/box-home-6.jpg'),
    id: 6,
    section: 1
  },
  {
    url: require('../images/box-home-1.jpg'),
    id: 7,
    section: 1
  },
  {
    url: require('../images/box-home-2.jpg'),
    id: 8,
    section: 1
  },
  {
    url: require('../images/box-home-3.jpg'),
    id: 9,
    section: 1
  },
  {
    url: require('../images/box-home-4.jpg'),
    id: 10,
    section: 1
  },
  {
    url: require('../images/box-home-5.jpg'),
    id: 11,
    section: 1
  },
  {
    url: require('../images/box-home-6.jpg'),
    id: 12,
    section: 1
  },
{
    url: require('../images/box-home-2.jpg'),
    id: 1,
    section: 2
  },
  {
    url: require('../images/box-home-3.jpg'),
    id: 2,
    section: 2
  },
  {
    url: require('../images/box-home-4.jpg'),
    id: 3,
    section: 2
  },
  {
    url: require('../images/box-home-5.jpg'),
    id: 4,
    section: 2
  },
  {
    url: require('../images/box-home-6.jpg'),
    id: 5,
    section: 2
  },
  {
    url: require('../images/box-home-1.jpg'),
    id: 6,
    section: 2
  },
  {
    url: require('../images/box-home-2.jpg'),
    id: 7,
    section: 2
  },
  {
    url: require('../images/box-home-3.jpg'),
    id: 8,
    section: 2
  },
  {
    url: require('../images/box-home-4.jpg'),
    id: 9,
    section: 2
  },
  {
    url: require('../images/box-home-5.jpg'),
    id: 10,
    section: 2
  },
  {
    url: require('../images/box-home-6.jpg'),
    id: 11,
    section: 2
  },
  {
    url: require('../images/box-home-1.jpg'),
    id: 12,
    section: 2
  },
  
]


export const zoomInData = [
  {
    url: require('../images/box-home-1.jpg'),
    id: 0,
    section: 1
  },
  {
    url: require('../images/box-home-2.jpg'),
    id: 1,
    section: 1
  },
  {
    url: require('../images/box-home-3.jpg'),
    id: 2,
    section: 1
  },
  {
    url: require('../images/box-home-4.jpg'),
    id: 3,
    section: 1
  },
  {
    url: require('../images/box-home-5.jpg'),
    id: 4,
    section: 1
  }
  
]

export const GalleryItems = [
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-1.jpg'),
    id: 1,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-2.jpg'),
    id: 2,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-3.jpg'),
    id: 3,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-4.jpg'),
    id: 4,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-5.jpg'),
    id: 5,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-6.jpg'),
    id: 6,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-7.jpg'),
    id: 7,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-3.jpg'),
    id: 8,
  },
  {
    className: 'true',
    alt: 'Galerie XS-Barbershop',
    description: 'true',
    image: require('../images/box-home-1.jpg'),
    id: 9,
  }
]

export const ToTopButtonItemsDefault = [

  {
    id: 2,
    classname: 'fas fa-home',
    function: false,
    link: '/'
  },
  {
    id: 1,
    classname: 'fas fa-arrow-up',
    function: true,
    totopbutton: true,
  },
  {
    id: 3,
    classname: 'fas fa-phone',
    function: false,
    externallink: 'tel:+061034877822'
  },
  {
    id: 4,
    classname: 'fas fa-envelope',
    function: false,
    externallink: 'mailto:info@xs-barbershop.com'
  }
]

export const listItems = [
  { id: 0, name: "VIP (Komplettprogramm Haare, Bart + Kosmetik)", category: "VIP", price: "€120", serviceId: "92911", bookable: false },
  { id: 1, name: "Trockenhaarschnitt", category: "Haare", price: "€ 36", serviceId: "39531", bookable: true },
  { id: 2, name: "Waschen - Schneiden - Föhnen", category: "Haare", price: "€ 39", serviceId: "39530", bookable: true },
  { id: 3, name: "Maschinenschnitt", category: "Haare", price: "€ 31", serviceId: "39534", bookable: true },
  { id: 4, name: "Kids Cut (bis 10 J.)", category: "Haare", price: "€ 26", serviceId: "76248", bookable: true },
  { id: 5, name: "Teen Cut (bis 17 J.)", category: "Haare", price: "€ 31", serviceId: "39564", bookable: true },
  { id: 6, name: "Kranzschnitt", category: "Haare", price: "€ 25", serviceId: "39538", bookable: true },
  { id: 7, name: "Nassrasur", category: "Bart", price: "€ 36", serviceId: "39536", bookable: true },
  { id: 8, name: "Bartpflege Basic", category: "Bart", price: "€ 31", serviceId: "39537", bookable: true },
  { id: 9, name: "Bartpflege Premium", category: "Bart", price: "€ 39", serviceId: "68787", bookable: true },
  { id: 10, name: "Kopfrasur", category: "Bart", price: "€ 31", serviceId: "68788", bookable: true },
  { id: 11, name: "Augenbrauen", category: "Kosmetik", price: "€ 10", serviceId: "54363", bookable: false },
  { id: 12, name: "Peeling / Mask", category: "Kosmetik", price: "€ 42", serviceId: "54364", bookable: false },
];